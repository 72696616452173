import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nameRegex, urlRegex } from "../../Utils/Regex";
import TextInput from "../Inputs/TextInput";
import { InputList, InputAddList, InputAddList1 } from "./TenantFormInputList";
import StepperHeader from "../StepperHeader";
import IndividualTenant from "./OnboardSteps/IndividualTenant";
import Res from "./OnboardSteps/Res";
import TenantAdmin from "./OnboardSteps/TenantAdmin";

function AddUpdateTenant() {
  let history = useHistory();
  const params = useParams();
  const [activeStep, setActiveStep] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [inputs, setInputs] = useState({
    tenantId: "",
    tenantUrl: "",
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    logoUrl: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    profileImage: "",
    username: "",
    password: "",
    gender: "",
    about: "",

    resfirstName: "",
    reslastName: "",
    resemail: "",
    resphone: "",
    resprofileImage: "",
    resusername: "",
    respassword: "",
    resgender: "",
    resabout: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onSubmit = () => {
    console.log("good");
  };

  useEffect(() => {
    setActiveStep(0);
  }, []);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="card border-none">
          <div className="row p-10">
            <div className="col-md-6">
              <h4>
                {params?.id ? "Update" : "On-boarding of individual"} Tenant
              </h4>
            </div>
            <div className="col-md-6">
              <button
                onClick={() => history.push("/tenant")}
                className="btn btn-theme-green float-right"
              >
                Back
              </button>
            </div>
          </div>
          <hr className="mt-0" />
          <StepperHeader
            steps={["Individual Tenant", "Tenant Admin", "RES"]}
            activeStep={activeStep}
          />
          <div className="col-md-8 p-10">
            {activeStep == 0 ? (
              <IndividualTenant
                setActiveStep={setActiveStep}
                inputs={inputs}
                setInputs={setInputs}
              />
            ) : activeStep == 1 ? (
              <TenantAdmin
                setActiveStep={setActiveStep}
                inputs={inputs}
                setInputs={setInputs}
              />
            ) : (
              activeStep == 2 && (
                <Res
                  setActiveStep={setActiveStep}
                  inputs={inputs}
                  setInputs={setInputs}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUpdateTenant;
