import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nameRegex, decimalRegex } from "../../Utils/Regex";
import TextInput from "../Inputs/TextInput";
import Service from "../../../Service";
import { toast } from "react-toastify";

function AddEditCptCode() {
  let history = useHistory();
  const params = useParams();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [inputs, setInputs] = useState({ cptFrequencies: null });
  const [therapyList, setTherapyList] = useState([]);

  useEffect(() => {
    getTherapyTypes();
  }, []);

  useEffect(() => {
    params?.id && getCptDetail(params?.id);
  }, [params?.id]);

  const getTherapyTypes = () => {
    Service.getMethod(`therapies`)
      .then((res) => {
        if (res.status == "Success") {
          setTherapyList(res?.data.content);
        }
      })
      .catch((err) => toast.error(err));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]:
        name == "encounter"
          ? value == "true"
            ? true
            : false
          : name == "duration"
          ? parseInt(value)
          : name == "maxFee"
          ? parseFloat(value)
          : name == "therapyId"
          ? parseInt(value)
          : value,
    });
  };

  const getCptDetail = (id) => {
    Service.getMethod(`cpts/${id}`)
      .then((res) => {
        if (res.status == "Success") {
          const data = res?.data;
          setInputs({ ...inputs, ...data, therapyId: res?.data.therapyDto.id });
          // setInputs({ ...inputs, therapy: res?.data.therapyDto.id });
          setValue("therapyId", res?.data.therapyDto.id);
          setValue("procCode", res?.data.procCode);
          setValue("encounter", res?.data.encounter);
          setValue("duration", res?.data.duration);
          setValue("noc", res?.data.noc);
          setValue("reportingSystem", res?.data.reportingSystem);
          setValue("frequencyFactor", res?.data.frequencyFactor);
          setValue("maxFee", res?.data.maxFee);
          setValue("description", res?.data.description);
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  const onSubmit = () => {
    const fn = params?.id
      ? Service.putMethod(`cpts/${params?.id}`, JSON.stringify(inputs))
      : Service.postMethod(`cpts`, JSON.stringify(inputs));

    fn.then((res) => {
      if (res.status == "Success") {
        res?.data && setInputs(res?.data);
        history.push("/cpt-codes");
      } else {
        toast.error(res?.message || "Somthing went wrong");
      }
    }).catch((err) => toast.error(err));
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="card border-none p-10">
          <div className="row">
            <div className="col-md-6">
              <h4>{params?.id ? "Edit" : "Add"} CPT Code</h4>
            </div>
            <div className="col-md-6">
              {/* <button
                onClick={() => history.push("/cpt-codes")}
                className="btn btn-theme-green float-right"
              >
                Back
              </button> */}
            </div>
          </div>
          <hr />
          <form className="p-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 form-group mb-0 add-session-form">
                <label>
                  Therapy Name <span class="required-span">*</span>
                </label>
                <div className="input-group">
                  <select
                    {...register("therapyId", {
                      required: true,
                    })}
                    className={`custom-select ${
                      errors.therapyId && "is-invalid"
                    }`}
                    onChange={handleChange}
                    defaultValue={inputs?.therapyId}
                    value={inputs?.therapyId}
                    name="therapyId"
                    disabled={params?.id ? "disabled" : ""}
                  >
                    <option value="">Select Therapy</option>
                    {therapyList.length > 0 &&
                      therapyList.map((th) => {
                        return <option value={th.id}>{th.name}</option>;
                      })}
                  </select>
                  <div className="input-group-prepend">
                    <img
                      className="m-auto"
                      src="../resources/assets/Polygon 1.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <TextInput
                  label="Proc Code"
                  name="procCode"
                  handleChange={handleChange}
                  require={{
                    ...register("procCode", {
                      required: true,
                    }),
                  }}
                  value={inputs?.procCode}
                  err={errors?.procCode}
                  isRequire={true}
                  placeholder="Proc Code"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group mb-0 add-session-form">
                <label>
                  Encounter <span class="required-span">*</span>
                </label>
                <div className="input-group">
                  <select
                    {...register("encounter", {
                      required: true,
                    })}
                    className={`custom-select ${
                      errors.encounter && "is-invalid"
                    }`}
                    onChange={handleChange}
                    defaultValue={inputs?.encounter}
                    value={inputs?.encounter}
                    name="encounter"
                  >
                    <option value="">Select Encounter</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                  <div className="input-group-prepend">
                    <img
                      className="m-auto"
                      src="../resources/assets/Polygon 1.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <TextInput
                  label="Dur/Min"
                  name="duration"
                  type="number"
                  handleChange={handleChange}
                  require={{
                    ...register("duration", {
                      required: true,
                      valueAsNumber: true,
                    }),
                  }}
                  value={inputs?.duration}
                  err={errors?.duration}
                  isRequire={true}
                  placeholder="Duration"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextInput
                  label="NOC"
                  name="noc"
                  handleChange={handleChange}
                  require={{
                    ...register("noc", {
                      required: true,
                    }),
                  }}
                  value={inputs?.noc}
                  err={errors?.noc}
                  isRequire={true}
                  placeholder="NOC"
                />
              </div>
              <div className="col-md-6">
                <TextInput
                  label="Reporting System"
                  name="reportingSystem"
                  handleChange={handleChange}
                  require={{
                    ...register("reportingSystem", {
                      required: true,
                    }),
                  }}
                  value={inputs?.reportingSystem}
                  err={errors?.reportingSystem}
                  isRequire={true}
                  placeholder="Reporting System"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextInput
                  label="Frequency Factor"
                  name="frequencyFactor"
                  handleChange={handleChange}
                  require={{
                    ...register("frequencyFactor", {
                      required: false,
                    }),
                  }}
                  value={null}
                  err={errors?.frequencyFactor}
                  isRequire={false}
                  placeholder="Frequency Factor"
                />
              </div>
              <div className="col-md-6">
                <TextInput
                  label="Max Fee"
                  name="maxFee"
                  // type="number"
                  handleChange={handleChange}
                  require={{
                    ...register("maxFee", {
                      required: true,
                      pattern: decimalRegex,
                    }),
                  }}
                  value={inputs?.maxFee}
                  err={errors?.maxFee}
                  isRequire={true}
                  placeholder="Max Fee"
                  step="0.1"
                />
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-md-12 form-group mb-0">
                <label>
                  Description <span class="required-span">*</span>
                </label>
                <textarea
                  {...register("description", {
                    required: true,
                  })}
                  name="description"
                  className={`form-control h-100 ${
                    errors?.description && "is-invalid"
                  }`}
                  onChange={(e) => handleChange(e)}
                  value={inputs?.description}
                  placeholder="Enter description"
                />
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12">
                <button type="submit" className="btn btn-theme-green">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditCptCode;
