import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import IcdCodeTable from "../CustomTable/IcdCodeTable";
import Search from "../Search";
import Service from "../../../Service";
import { toast } from "react-toastify";
import Pagination from "react-responsive-pagination";

function IcdTenCode() {
  let history = useHistory();
  const [search, setSearch] = useState("");
  const [icdCodeList, setIcdCodeList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const onSearch = () => {
    getIcdCodeList();
  };

  useEffect(() => {
    getIcdCodeList();
  }, [search, page]);

  const getIcdCodeList = (id) => {
    Service.getMethod(
      `diagnosis?search=${search}&page=${page}&size=10&sort=id,desc`
    )
      .then((res) => {
        if (res.status == "Success") {
          setIcdCodeList(res?.data.content);
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  const deleteData = (id) => {
    Service.deleteMethod(`diagnosis/${id}`)
      .then((res) => {
        if (res.status == "Success") {
          setConfirmDeletion(false);
          toast.success(res?.message);
          getIcdCodeList();
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  return (
    <div className="col-md-10 no-padding">
      <div className="card border-none p-10">
        <div className="row">
          <div className="col-md-6">
            <h4>ICD 10 Codes</h4>
          </div>
          <div className="col-md-6">
            <button
              onClick={() => history.push("/add-icd-10-code")}
              className="btn btn-theme-green float-right"
            >
              + ICD 10 Code
            </button>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 mt-10">
            <Search search={search} setSearch={setSearch} onSearch={onSearch} />
          </div>
          <div className="col-md-12 mt-10">
            <IcdCodeTable
              headRow={["Therapy Name", "ICD 10 code", "Description", "Action"]}
              list={icdCodeList || []}
              onDelete={deleteData}
              confirmDeletion={confirmDeletion}
              setConfirmDeletion={setConfirmDeletion}
            />
          </div>
          <div className="col-md-12 mt-10">
            <Pagination
              total={totalPages}
              current={page + 1}
              onPageChange={(page) => setPage(page - 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IcdTenCode;
