import React from "react";

function TextInput(props) {
  return (
    <>
      <div className="form-group mb-15">
        {props?.label && <label>{props.label}&nbsp;</label>}
        {props?.isRequire && <span class="required-span">*</span>}
        <input
          type={props?.type ? props?.type : "text"}
          name={props?.name}
          {...props?.require}
          // required={props?.isRequire}
          className={`form-control ${props.err && "is-invalid"}`}
          onChange={(e) => props.handleChange(e)}
          defaultValue={props?.value}
          // value={props?.value}
          placeholder={`Enter ${props?.placeholder}`}
          {...props?.step}
        />
      </div>
    </>
  );
}

export default TextInput;
