import React from "react";

function Forms() {
  return (
    <div className="col-md-10 no-padding">
      <div className="card border-none p-10">
        <div className="row">
          <div className="col-md-6">
            <h4>Forms</h4>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
    </div>
  );
}

export default Forms;
