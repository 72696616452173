import React from "react";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

const ErrorPage = () => {
  // const user = useSelector(state => state.adminUser.user);
  const isLoggedIn = false;
  //   user && user.accessToken && !user.userDetails.newUser
  // let tenantInfo = useSelector(state => state.adminUser.tenantInfo);
  return (
    <div
      className={classNames("c-error-page text-grey pb-5", {
        "col-md-10": isLoggedIn,
        "col-md-12": !isLoggedIn,
      })}
    >
      {/* {tenantInfo?.largeLogoUrl && <img className="mb-3 c-error-page__imagee" src={tenantInfo.largeLogoUrl} alt={tenantInfo.tenantName} />} */}
      <div className="display-1 pb-4">Oops!</div>
      <div className="display-4 pb-3">Page Not Found.</div>
      <p>
        Go back to{" "}
        <Link to="/" className="text-primary">
          {isLoggedIn ? "Dashboard" : "Login"}
        </Link>
      </p>
    </div>
  );
};

export default ErrorPage;
