import React, { useState } from "react";
import TextInput from "../../Inputs/TextInput";
import { nameRegex, urlRegex } from "../../../Utils/Regex";
import { useForm } from "react-hook-form";
import InputFileBtn from "../../Inputs/InputFileBtn";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadFile from "../../../Utils/UploadFile";
import IndividualTenantForm from "../OnboardSteps/IndividualTenantForm";
import TenantAdminForm from "../OnboardSteps/TenantAdminForm";
import ResForm from "../OnboardSteps/ResForm";

function EditRes() {
  let params = useParams();
  let history = useHistory();
  const [inputs, setInputs] = useState({
    resfirstName: "",
    reslastName: "",
    resemail: "",
    resphone: "",
    resusername: "",
    respassword: "",
    resprofileImage: "",
    resabout: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onSubmit = () => {
    console.log("good");
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    UploadFile(e, setInputs);
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="card border-none">
          <div className="row p-10">
            <div className="col-md-6">
              <h4>{params?.resId ? "Edit" : "Add"} Tenant RES</h4>
            </div>
            <div className="col-md-6">
              <button
                onClick={() => history.push("/tenant-detail/1")}
                className="btn btn-theme-green float-right"
              >
                Back
              </button>
            </div>
          </div>
          <hr className="mt-0" />
          <div className="col-md-12">
            <ResForm
              onSubmit={onSubmit}
              handleChange={handleChange}
              inputs={inputs}
              handleImageChange={handleImageChange}
              isOnboard={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditRes;
