import React from "react";
import { toast } from "react-toastify";
const UploadFile = (e, setInputs) => {
  let reader = new FileReader();
  let file = e.target.files[0];
  let FileSize = e.target.files[0].size / 1024 / 1024; // in MB
  if (file.name.match(/.(jpg|jpeg|jpeg2000|png|svg)$/i)) {
    if (FileSize > 2) {
      toast.warn("Please upload file having size MAX 2MB");
    } else {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        file.url = reader.result;
        file.isnew = true;
        setInputs((inputs) => ({ ...inputs, [e.target.name]: file }));
      };
    }
  } else {
    toast.warn("Please upload file having extensions - JPG,JPEG,PNG,SVG");
  }
};
export default UploadFile;
