import { apiUrl } from "./Config";
import { toast } from "react-toastify";

const header = () => {
  const user = localStorage.getItem("superadmin")
    ? JSON.parse(localStorage.getItem("superadmin"))
    : {};
  if (user && user.accessToken) {
    return {
      Authorization: "Bearer " + user.accessToken,
      SessionId: user.sessionId,
      "Content-Type": "application/json",
      Accept: "application/json",
      mode: "cors",
      "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json",
      // Accept: "application/json",
      // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS, PATCH",
      // "Access-Control-Allow-Headers":
      //   "Content-Type, Authorization, Content-Length, X-Requested-With, Accept",
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      mode: "cors",
      "Access-Control-Allow-Origin": "*",
    };
  }
};

function logout() {
  patchMethod("auth/logout")
    .then((res) => {
      console.log("res");
      if (res.status == "Success") {
        localStorage.removeItem("superadmin");
        window.location.href = "/login";
      } else {
        toast.error(res.message);
      }
    })
    .catch((err) => toast.error(err));
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      // if any error like validation check or data not found, etc...
      if (response.status === 400) {
        return data;
      }
      if (response.status === 401 || response.status === 403) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      //return Promise.reject(error);
      return data;
    }
    if (data.code == 401) {
      logout();
    }

    return data;
  });
}

function postMethod(endpoint, dataobj) {
  let options = {
    method: "POST",
    headers: header(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function putMethod(endpoint, dataobj) {
  let options = {
    method: "PUT",
    headers: header(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function patchMethod(endpoint) {
  let options = {
    method: "PATCH",
    headers: header(),
    // body: JSON.stringify({}),
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function getMethod(endpoint, signal) {
  const requestOptions = {
    method: "GET",
    headers: header(),
    signal,
  };
  return fetch(`${apiUrl}/${endpoint}`, requestOptions).then(handleResponse);
}

function deleteMethod(endpoint, signal) {
  const requestOptions = {
    method: "DELETE",
    headers: header(),
    signal,
  };
  return fetch(`${apiUrl}/${endpoint}`, requestOptions).then(handleResponse);
}

const Service = {
  postMethod,
  putMethod,
  patchMethod,
  getMethod,
  deleteMethod,
};

export default Service;
