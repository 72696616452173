import React from "react";
import {
  Route,
  Switch,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Login from "./components/Login";
import Dashboard from "./components/Admin/Dashboard";
import Sidebar from "./components/Admin/Sidebar";
import Header from "./components/Admin/Header";
import Tenant from "./components/Admin/Tenant";
import AddUpdateTenant from "./components/Admin/Tenant/AddUpdateTenant";
import IcdTenCode from "./components/Admin/TherapistLicense/IcdTenCode";
import AddEditIcdTenCode from "./components/Admin/TherapistLicense/AddEditIcdTenCode";
import Forms from "./components/Admin/Forms";
import { useUserContext } from "./components/Context/UserContext";
import TenantDetail from "./components/Admin/Tenant/TenantDetail";
import AddEditIndividual from "./components/Admin/Tenant/AddEdit/AddEditIndividual";
import AddEditAdmin from "./components/Admin/Tenant/AddEdit/AddEditAdmin";
import AddEditRes from "./components/Admin/Tenant/AddEdit/AddEditRes";
import OtherUser from "./components/Admin/OtherUser";
import AddEditOtherUser from "./components/Admin/OtherUser/AddEditOtherUser";
import OtherUserDetail from "./components/Admin/OtherUser/OtherUserDetail";
import TherapyTypes from "./components/Admin/TherapistLicense/TherapyTypes";
import AddEditTherapyType from "./components/Admin/TherapistLicense/AddEditTherapyType";
import Settings from "./components/Admin/Settings";
import CptCode from "./components/Admin/TherapistLicense/CptCode";
import AddEditCptCode from "./components/Admin/TherapistLicense/AddEditCptCode";

const RepresentativeRoute = (authToken = "") => {
  return authToken ? (
    <>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/tenant/" component={Tenant} />
        <Route exact path="/add-tenant/" component={AddUpdateTenant} />
        <Route exact path="/tenant-detail/:id" component={TenantDetail} />

        <Route exact path="/add-tenant-admin/:id" component={AddEditAdmin} />
        <Route exact path="/add-tenant-res/:id" component={AddEditRes} />

        <Route exact path="/edit-tenant/:id" component={AddEditIndividual} />
        <Route
          exact
          path="/edit-tenant-admin/:id/:adminId"
          component={AddEditAdmin}
        />
        <Route
          exact
          path="/edit-tenant-res/:id/:resId"
          component={AddEditRes}
        />

        <Route exact path="/other-users/" component={OtherUser} />
        <Route exact path="/add-other-user" component={AddEditOtherUser} />
        <Route exact path="/edit-other-user/:id" component={AddEditOtherUser} />
        <Route
          exact
          path="/other-user-detail/:id"
          component={OtherUserDetail}
        />

        <Route exact path="/icd-10-codes/" component={IcdTenCode} />
        <Route exact path="/add-icd-10-code/" component={AddEditIcdTenCode} />
        <Route
          exact
          path="/edit-icd-10-code/:id"
          component={AddEditIcdTenCode}
        />

        <Route exact path="/cpt-codes/" component={CptCode} />
        <Route exact path="/add-cpt-code/" component={AddEditCptCode} />
        <Route exact path="/edit-cpt-code/:id" component={AddEditCptCode} />

        <Route exact path="/therapy-types/" component={TherapyTypes} />
        <Route exact path="/add-therapy-type/" component={AddEditTherapyType} />
        <Route
          exact
          path="/edit-therapy-type/:id"
          component={AddEditTherapyType}
        />

        <Route exact path="/settings/" component={Settings} />

        <Route exact path="/forms/" component={Forms} />

        <Route path="" component={ErrorPage} />
      </Switch>
    </>
  ) : (
    <>{AuthRoutes()}</>
  );
};

const AuthRoutes = () => {
  return (
    <Switch>
      {/* <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/tenant/" component={Tenant} />
      <Route exact path="/add-tenant/" component={AddUpdateTenant} />
      <Route exact path="/edit-tenant/:id" component={AddUpdateTenant} />

      <Route exact path="/tenant/" component={Tenant} />
      <Route exact path="/add-tenant/" component={AddUpdateTenant} />
      <Route exact path="/edit-tenant/:id" component={AddUpdateTenant} />

      <Route exact path="/therapist/" component={Therapist} />
      <Route exact path="/add-therapist/" component={AddUpdateTherapist} />
      <Route exact path="/edit-therapist/:id" component={AddUpdateTherapist} />

      <Route exact path="/time-zone/" component={Forms} />

      <Route exact path="/forms/" component={Forms} />

      <Route exact path="/admin-user/" component={AdminUser} />
      <Route exact path="/add-admin-user/" component={AddUpdateAdminUser} />
      <Route exact path="/edit-admin-user/:id" component={AddUpdateAdminUser} /> */}

      <Route exact path="/" component={Login} />
      <Route exact path="/login/" component={Login} />
      <Route path="" component={ErrorPage} />
    </Switch>
  );
};
function Router() {
  const { user } = useUserContext();
  return (
    <>
      {user?.accessToken && <Header />}
      <div className="d-flex flex-column flex-grow-1">
        <div className="container-fluid d-flex flex-column flex-grow-1">
          <div className="row flex-grow-1">
            {user?.accessToken && <Sidebar />}
            {RepresentativeRoute(user?.accessToken)}
          </div>
        </div>
      </div>
    </>
  );
}

export default Router;
