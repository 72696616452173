import React from "react";
import TextInput from "../../Inputs/TextInput";
import { nameRegex, emailRegex } from "../../../Utils/Regex";
import { useForm } from "react-hook-form";
import InputFileBtn from "../../Inputs/InputFileBtn";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadFile from "../../../Utils/UploadFile";
import RadioInput from "../../Inputs/RadioInput";
import TenantAdminForm from "./TenantAdminForm";

function TeamAdmin(props) {
  const { inputs, setInputs, setActiveStep } = props;
  let params = useParams();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onSubmit = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    UploadFile(e, setInputs);
  };
  return (
    <>
      <TenantAdminForm
        onSubmit={onSubmit}
        handleChange={handleChange}
        inputs={inputs}
        handleImageChange={handleImageChange}
        isOnboard={true}
      />
    </>
  );
}

export default TeamAdmin;
