export const nameRegex =
  /^[a-zA-Z\s'àèéëïĳáêíîôóúûÆØÅæøåÄÖäöBCDFGQWXZbcfgqwxzŠšŽžÕÜõüßÇÊÎŞÛçşĂÂȘȚăâșțÔŴŶÁÉÍÏŵŷÓÚẂÝÀÈÌÒÙẀỲËẄŸẃýìòùẁỳẅÿĈĜĤĴŜŬĉĝĥĵŝŭĞİğıÐÞðþŐŰőű·ŒœãÑñÃẼĨŨỸ̃ẽĩũỹĄ́ĘĮŁŃ ąęįłń’Ǫā̄ēīōǫǭū'ŲųżćśźůA,EHIJKLMNOPRSTUYŻČĆĐĎĚŇŘŤŮďěňřťĽĹŔľĺŕĀĒĢĪĶĻŅŌŖŪģķļņŗĖėẢẠẰẲẴẮẶẦẨẪẤẬẺẸỀỂỄẾỆỈỊỎỌỒỔỖỐỘƠỜỞỠỚỢỦỤƯỪỬỮỨỰỶỴđảạằẳẵắặầẩẫấậẻẹềểễếệỉịỏọồổỗốơờởỡớợủụưừửữứựỷỵĕŏo᷄uАБВГДЕЖЗИКЛМНОПРСТУФХЦЧШЙЩЬЮЯЉЊЏ]+$/;

export const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const decimalRegex = /^\d+\.\d{0,5}$/;
