import React, { useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import TabLayout from "../TabLayout";
import AdminRESTable from "./AdminRESTable";

function TenantDetail() {
  let history = useHistory();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState("tenant-admin");
  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="card border-none">
          <div className="row p-10">
            <div className="col-md-6">
              <h4>Tenant Details</h4>
            </div>
            <div className="col-md-6">
              <button
                onClick={() => history.push("/tenant")}
                className="btn btn-theme-green float-right"
              >
                Back
              </button>
            </div>
          </div>
          <hr className="mt-0" />
          <div className="col-md-12">
            <div className="row m-0">
              <div className="col-md-2">
                <img
                  src={"/resources/assets/defaultprofile.png"}
                  alt="User profile img"
                  className="tenant-detail-logo"
                  width="100%"
                />
              </div>
              <div className="col-md-9">
                <h4 className="mt-10">Tenant Name</h4>
                <a href="#">Tenent Url</a>
              </div>
              <div className="col-md-1">
                <img
                  src={"/resources/assets/action-icon-edit.svg"}
                  alt="User profile img"
                  className="float-right"
                />
              </div>
            </div>
          </div>
          <div className="row mt-10">
            <div className="col-md-12">
              <TabLayout
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                list={[
                  {
                    title: "Tenant Admin",
                    noOfUser: `${5} Admin`,
                    manageId: "tenant-admin",
                    component: <AdminRESTable list={[1, 2, 3, 4, 5]} />,
                    buttonComp: (
                      <button
                        className="btn btn-theme-green float-right"
                        onClick={() => history.push("/add-tenant-admin/1")}
                      >
                        +Tenant Admin
                      </button>
                    ),
                  },
                  {
                    title: "Tenant Res",
                    noOfUser: `${3} Res`,
                    manageId: "tenant-res",
                    component: <AdminRESTable list={[1, 2, 3]} />,
                    buttonComp: (
                      <button
                        className="btn btn-theme-green float-right"
                        onClick={() => history.push("/add-tenant-res/1")}
                      >
                        +Tenant RES
                      </button>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TenantDetail;
