import React from "react";
import TextInput from "../../Inputs/TextInput";
import { nameRegex, emailRegex } from "../../../Utils/Regex";
import { useForm } from "react-hook-form";
import InputFileBtn from "../../Inputs/InputFileBtn";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadFile from "../../../Utils/UploadFile";
import RadioInput from "../../Inputs/RadioInput";

function ResForm(props) {
  let params = useParams();
  const { onSubmit, handleChange, inputs, handleImageChange, isOnboard } =
    props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <>
      <form
        className="addtheripist add-session-form p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row mt-10">
          <div className="col-12">
            <div className="form-group">
              <label>Profile Picture</label>{" "}
              {params.id ? (
                <span>
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={
                          inputs?.resprofileImage
                            ? inputs.resprofileImage
                            : "/resources/assets/defaultprofile.png"
                        }
                        alt=""
                        className="settingsimg"
                      />
                    </div>
                    <InputFileBtn
                      id="fileuplaod"
                      name="resprofileImage"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                  <br />
                </span>
              ) : (
                <label for="fileuplaod" className="fileuplaod">
                  <div className="content-box">
                    <img
                      src={
                        inputs?.resprofileImage
                          ? inputs.resprofileImage
                          : "../resources/assets/file-upload-icon.svg"
                      }
                      alt="Family"
                    />
                    <div className="file-title">
                      Drop your image here, or <strong>browse</strong>{" "}
                    </div>
                    <div className="file-name">Support- JPG,JPEG,PNG,SVG</div>
                  </div>
                  <input
                    type="file"
                    id="fileuplaod"
                    name="resprofileImage"
                    onChange={(e) => handleImageChange(e)}
                    //ref={register({ required: inputs.profileImage ? false : true })}
                  />
                </label>
              )}
              {errors.resprofileImage &&
                errors.resprofileImage.type === "required" && (
                  <div className="invalid-feedback-custom">
                    Please upload profile picture
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-md-6">
            <TextInput
              label="First Name"
              name="resfirstName"
              handleChange={handleChange}
              require={{
                ...register("resfirstName", {
                  required: true,
                  maxLength: 50,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.resfirstName}
              err={errors?.resfirstName}
              isRequire={true}
              placeholder="first name"
            />
          </div>
          <div className="col-md-6">
            <TextInput
              label="Last Name"
              name="reslastName"
              handleChange={handleChange}
              require={{
                ...register("reslastName", {
                  required: true,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.reslastName}
              err={errors?.reslastName}
              isRequire={true}
              placeholder="last name"
            />
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-md-6">
            <TextInput
              label="Email"
              name="resemail"
              handleChange={handleChange}
              require={{
                ...register("resemail", {
                  required: true,
                  maxLength: 50,
                  pattern: emailRegex,
                }),
              }}
              value={inputs?.resemail}
              err={errors?.resemail}
              isRequire={true}
              placeholder="an email"
            />
          </div>
          <div className="col-md-6">
            <TextInput
              type="number"
              label="Phone Number"
              name="resphone"
              handleChange={handleChange}
              require={{
                ...register("resphone", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                }),
              }}
              value={inputs?.resphone}
              err={errors?.resphone}
              isRequire={true}
              placeholder="phone number"
            />
          </div>
        </div>

        {!params?.id && (
          <div className="row mt-10">
            <div className="col-md-6">
              <TextInput
                type="password"
                label="Password"
                name="respassword"
                handleChange={handleChange}
                require={{
                  ...register("respassword", {
                    required: true,
                    maxLength: 50,
                    pattern: nameRegex,
                  }),
                }}
                value={inputs?.respassword}
                err={errors?.respassword}
                isRequire={true}
                placeholder="password"
              />
            </div>
            <div className="col-md-6">
              <TextInput
                type="password"
                label="Confirm password"
                name="rescpassword"
                handleChange={handleChange}
                require={{
                  ...register("rescpassword", {
                    required: true,
                    pattern: nameRegex,
                  }),
                }}
                value={inputs?.rescpassword}
                err={errors?.rescpassword}
                isRequire={true}
                placeholder="confirm password"
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12 form-group mb-15">
            <label>Gender</label> (<span className="required-span">*</span>)
            <br />
            <RadioInput
              label="Male"
              name="resgender"
              handleChange={handleChange}
              require={{
                ...register("resgender", {
                  required: true,
                }),
              }}
              val="MALE"
              value={inputs?.resgender}
              err={errors?.resgender}
              id="customRadi1"
            />
            <RadioInput
              label="Female"
              name="resgender"
              handleChange={handleChange}
              require={{
                ...register("resgender", {
                  required: true,
                }),
              }}
              val="FEMALE"
              value={inputs?.resgender}
              err={errors?.resgender}
              id="customRadio2"
            />
            <RadioInput
              label="Other"
              name="gender"
              handleChange={handleChange}
              require={{
                ...register("gender", {
                  required: true,
                }),
              }}
              val="NOT_DISCLOSED"
              value={inputs?.gender}
              err={errors?.gender}
              id="customRadio3"
            />
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-md-12 form-group mb-0">
            <label>
              About <span class="required-span">*</span>
            </label>
            <textarea
              name="resabout"
              handleChange={handleChange}
              {...register("resabout", {
                required: true,
                pattern: nameRegex,
              })}
              value={inputs?.resabout}
              isRequire={true}
              placeholder="Enter about tenant admin"
              className={`form-control h-100 ${
                errors?.resabout && "is-invalid"
              }`}
            />
          </div>

          <div className="col-md-12 mt-10">
            <button type="submit" className="btn btn-theme-green nxt-btn">
              {isOnboard ? "Next" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ResForm;
