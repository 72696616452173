import React from "react";

function UserCountCard(props) {
  return (
    <>
      <div className="row">
        {props?.list.map((data) => {
          return (
            <div className="col-md-6 mb-10">
              <div className="card card-1">
                <div className="card-body text-center">
                  <img
                    src="../resources/assets/filter-icon-user.svg"
                    alt=""
                    className="card-icon"
                  />
                  <h5 className="card-title">{data.count}</h5>
                  <p className="card-text">{data.title}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default UserCountCard;
