import React from "react";
import { Link } from "react-router-dom";
import UserCountCard from "./UserCountCard";

function index() {
  return (
    <div className="col-md-10 no-padding">
      <div className="row dasboard-count-user">
        <div className="col-md-6 mb-10">
          <div className="card card-1">
            <div className="card-body text-center"></div>
          </div>
        </div>
        <div className="col-md-6">
          <UserCountCard
            list={[
              {
                title: "Therapist",
                count: 10,
                icon: "../resources/assets/filter-icon-user.svg",
              },
              {
                title: "Family",
                count: 10,
                icon: "../resources/assets/Icon feather-users.svg",
              },
              {
                title: "Interpreter",
                count: 10,
                icon: "../resources/assets/translation.svg",
              },
              {
                title: "Tenant",
                count: 10,
                icon: "../resources/assets/filter-icon-user.svg",
              },
            ]}
          />
        </div>
        <div className="col-md-12 mt-10">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4>Billing Information</h4>
                </div>
                <div className="col-md-6">
                  <button className="btn btn-warning btn-view-more float-right">
                    View More
                  </button>
                </div>
              </div>
              <hr />
              <div className="row">
                {/* <div className="col-md-3"></div> */}
                <div className="col-md-4">
                  <div className="card card-1 blue-card">
                    <div className="card-body text-center"></div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-1 blue-card">
                    <div className="card-body text-center"></div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-1 blue-card">
                    <div className="card-body text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
