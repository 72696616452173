import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Service from "../Service";

function Login() {
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const { email, password } = inputs;
  // localStorage.setItem("user",JSON.stringify(action.payload))
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const onSubmit = (props) => {
    if (email && password) {
      let loginreq = {
        email: email,
        password: password,
      };
      let options = {
        body: JSON.stringify(loginreq),
      };
      Service.postMethod("auth/login", JSON.stringify(loginreq))
        .then((res) => {
          console.log("res");
          if (res.status == "Success") {
            localStorage.setItem("superadmin", JSON.stringify(res.data));
            toast.success(res.message);
            window.location.href = "/dashboard";
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => toast.error(err));
    }
  };
  return (
    <>
      <div className="col">
        <div className="container p-0">
          <div className="row align-items-center login-page">
            <div className="col-md-6 no-padding p-0">
              <div className="log-wrapper-left">
                <img
                  src="../resources/assets/Mask Group 2.png"
                  className="mask-login-left"
                />
                {
                  <img
                    src="../resources/assets/MIS-1.png"
                    className="login-logo"
                  />
                }
                {/*window.location.host !== "cfdx.mis-live.com" ? <img  className="login-logo"  src={'../resources/assets/MIS-1.png'} alt="" />: <img  className="login-logo"  src={'https://meid-dev-public.s3.us-east-2.amazonaws.com/billinglogo.png'} alt="" /> */}
              </div>
              <img
                src="../resources/assets/left-login-img.jpg"
                className="left-img img-fluid"
              />
              <img
                src="../resources/assets/MIS-1.png"
                className="copyright-image "
              />
              <span className="copyright-text">Copyright @RCE 2021</span>
            </div>

            <div className="col-md-6 no-padding">
              <div className="row valign_wrapper padd-b-60-all ">
                <div className="custom-table-view-login">
                  <div className="col-lg-12 min-hei-250-all">
                    <div className="text-center login">
                      {/* <img className="mb-3 mb-sm-5 tenant-logo-img" src={tenantinfo && tenantinfo.largeLogoUrl} alt="" />*/}
                      {window.location.host !== "cfdx.mis-live.com" ? (
                        <img
                          className="mb-3 mb-sm-5 tenant-logo-img"
                          //   src={tenantinfo && tenantinfo.largeLogoUrl}
                          alt=""
                        />
                      ) : window.location.host === "acay.mis-live.com" ? (
                        <img
                          className="mb-3 mb-sm-5 tenant-logo-img"
                          src={
                            "https://meid-dev-public.s3.us-east-2.amazonaws.com/Achievement+Academy+Logo.png"
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          className="mb-3 mb-sm-5 tenant-logo-img"
                          src={
                            "https://meid-dev-public.s3.us-east-2.amazonaws.com/billinglogo.png"
                          }
                          alt=""
                        />
                      )}
                      <div className="form-signin-wrapper">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autocomplete="off"
                          className="custom-form form-custome-elements floting-form"
                        >
                          <h1 className="h3 mb-3 font-weight-normal text-left">
                            Login
                          </h1>
                          <div className="form-group">
                            <input
                              name="email"
                              onChange={handleChange}
                              defaultValue={email}
                              type="text"
                              id="inputEmail"
                              className={"form-control"}
                              required
                            />
                            <label
                              className="form-control-placeholder"
                              for="inputEmail"
                            >
                              Enter Email/Mobile
                            </label>
                          </div>
                          <div className="form-group">
                            <input
                              name="password"
                              onChange={handleChange}
                              defaultValue={password}
                              type="password"
                              id="inputPassword"
                              className="form-control"
                              autoComplete="off"
                              required
                            />
                            <label
                              className="form-control-placeholder"
                              for="inputPassword"
                            >
                              Enter Password
                            </label>
                          </div>
                          <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                              <button
                                className="btn custom-btn-green"
                                type="submit"
                                disabled={loading}
                                block={true}
                              >
                                Login
                              </button>
                            </div>
                            <div className="col-auto">
                              <div className="checkbox">
                                <Link to={`/forgot-password`}>
                                  <u>Forgot password?</u>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
