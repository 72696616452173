import React from "react";

function OtherTable(props) {
  return (
    <div className="table-responsive">
      <table class="table">
        <thead>
          <tr>
            {props.headRow.map((data) => {
              return <th scope="col">{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {props.list.map((data) => {
            return (
              <tr>
                <td>
                  <img
                    className="small-tenant-img"
                    src={
                      data?.logoUrl
                        ? data?.logoUrl
                        : "../../../resources/assets/defaultprofile.png"
                    }
                  />
                </td>
                <td>{data?.firstName + " " + data?.lastName}</td>
                <td>{data?.email}</td>
                <td>{data?.phone}</td>
                {props?.tableName == "other" && <td>{data?.gender}</td>}
                <td>Action</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default OtherTable;
