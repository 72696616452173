import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import IcdCodeTable from "../CustomTable/IcdCodeTable";
import TherapyTypeTable from "../CustomTable/TherapyTypeTable";
import Search from "../Search";
import Service from "../../../Service";
import { toast } from "react-toastify";
import Pagination from "react-responsive-pagination";

function TherapyTypes() {
  let history = useHistory();
  const [search, setSearch] = useState("");
  const [therapyList, setTherapyList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const onSearch = () => {
    getTherapyTypes();
  };

  useEffect(() => {
    getTherapyTypes();
  }, [search, page]);

  const getTherapyTypes = () => {
    Service.getMethod(
      `therapies?search=${search}&page=${page}&size=10&sort=id,desc`
    )
      .then((res) => {
        if (res.status == "Success") {
          setTherapyList(res?.data.content);
          setTotalPages(res.data.totalPages);
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };
  return (
    <div className="col-md-10 no-padding">
      <div className="card border-none p-10">
        <div className="row">
          <div className="col-md-6">
            <h4>Therapy Types</h4>
          </div>
          <div className="col-md-6">
            <button
              onClick={() => history.push("/add-therapy-type")}
              className="btn btn-theme-green float-right"
            >
              + Therapy Types
            </button>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 mt-10">
            <Search search={search} setSearch={setSearch} onSearch={onSearch} />
          </div>
          <div className="col-md-12 mt-10">
            <TherapyTypeTable
              headRow={[
                "Therapy Name",
                "Therapy Code",
                "Description",
                "Action",
              ]}
              list={therapyList || []}
            />
          </div>
          <div className="col-md-12 mt-10">
            <Pagination
              total={totalPages}
              current={page + 1}
              onPageChange={(page) => setPage(page - 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TherapyTypes;
