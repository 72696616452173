import React, { useState } from "react";
import OtherTable from "../CustomTable/OtherTable";
import { useParams, useHistory } from "react-router-dom";
import Search from "../Search";
import TimeZone from "../CustomTable/TimeZone";

function Settings() {
  const params = useParams();
  let history = useHistory();
  const [search, setSearch] = useState("");
  const onSearch = () => {
    console.log("search", search);
  };
  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding">
          <div className="custom-table-view">
            <div className="pb-0">
              <div className="row">
                <div className="col-md-12">
                  <div class="header-title">
                    {params.id ? "Edit" : "Add New"} Session
                  </div>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-md-12 p-0 mt-10">
                  <div className="header-btn">
                    <ul
                      className="nav nav-tabs settings-tab"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="add-admin-tab"
                          data-toggle="tab"
                          href="#add-admin"
                          role="tab"
                          aria-controls="add-admin"
                          aria-selected="true"
                        >
                          MIS Admin
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="timezone-tab"
                          data-toggle="tab"
                          href="#timezone"
                          role="tab"
                          aria-controls="timezone"
                          aria-selected="false"
                        >
                          Timezone
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="language-tab"
                          data-toggle="tab"
                          href="#language"
                          role="tab"
                          aria-controls="language"
                          aria-selected="false"
                        >
                          Language
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="email-temp-tab"
                          data-toggle="tab"
                          href="#email-temp"
                          role="tab"
                          aria-controls="email-temp"
                          aria-selected="false"
                        >
                          Email Templates
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="sms-temp-tab"
                          data-toggle="tab"
                          href="#sms-temp"
                          role="tab"
                          aria-controls="sms-temp"
                          aria-selected="false"
                        >
                          SMS Templates
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="notification-tab"
                          data-toggle="tab"
                          href="#notification"
                          role="tab"
                          aria-controls="notification"
                          aria-selected="false"
                        >
                          Notification
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="online-form-tab"
                          data-toggle="tab"
                          href="#online-form"
                          role="tab"
                          aria-controls="online-form"
                          aria-selected="false"
                        >
                          Online Forms
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-block tab-content"
              id="myTabContent"
              style={{ paddingBottom: 20 }}
            >
              {/* admin */}
              <div
                className="tab-pane fade active show"
                id="add-admin"
                role="tabpanel"
                aria-labelledby="add-admin-tab"
              >
                <div className="row">
                  <div className="col-md-6 mt-10">
                    <Search
                      search={search}
                      setSearch={setSearch}
                      onSearch={onSearch}
                    />
                  </div>
                  <div className="col-md-6 mt-10">
                    <button className="btn btn-theme-green float-right mt-1">
                      + MIS Admin
                    </button>
                  </div>
                  <div className="col-md-12 mt-10">
                    <OtherTable
                      headRow={["", "Name", "Email", "Phone", "Action"]}
                      list={[]}
                    />
                  </div>
                </div>
              </div>
              {/* timezone */}
              <div
                className="tab-pane fade"
                id="timezone"
                role="tabpanel"
                aria-labelledby="timezone-tab"
              >
                <div className="row">
                  <div className="col-md-6 mt-10">
                    <Search
                      search={search}
                      setSearch={setSearch}
                      onSearch={onSearch}
                      placeholder="timezone"
                    />
                  </div>
                  <div className="col-md-6 mt-10">
                    <button className="btn btn-theme-green float-right mt-1">
                      + Timezone
                    </button>
                  </div>
                  <div className="col-md-12 mt-10">
                    <TimeZone
                      headRow={["Time Zone", "Country Code", "Action"]}
                      list={[]}
                    />
                  </div>
                </div>
              </div>
              {/* labguage */}
              <div
                className="tab-pane fade"
                id="language"
                role="tabpanel"
                aria-labelledby="language-tab"
              >
                <div className="row">
                  <div className="col-md-6 mt-10">
                    <Search
                      search={search}
                      setSearch={setSearch}
                      onSearch={onSearch}
                      placeholder="language"
                    />
                  </div>
                  <div className="col-md-6 mt-10">
                    <button className="btn btn-theme-green float-right mt-1">
                      + Language
                    </button>
                  </div>
                  <div className="col-md-12 mt-10">
                    <TimeZone
                      headRow={["Language", "Language Code", "Action"]}
                      list={[]}
                    />
                  </div>
                </div>
              </div>
              {/* email temp */}
              <div
                className="tab-pane fade"
                id="email-temp"
                role="tabpanel"
                aria-labelledby="email-temp-tab"
              >
                <div className="row">
                  <div className="col-md-6 mt-10">
                    <Search
                      search={search}
                      setSearch={setSearch}
                      onSearch={onSearch}
                      placeholder="email name"
                    />
                  </div>
                  <div className="col-md-6 mt-10">
                    <button className="btn btn-theme-green float-right mt-1">
                      + Email Template
                    </button>
                  </div>
                  <div className="col-md-12 mt-10">
                    {/* <TimeZone
                      headRow={["Time Zone", "Country Code", "Action"]}
                      list={[]}
                    /> */}
                  </div>
                </div>
              </div>
              {/* sms temp */}
              <div
                className="tab-pane fade"
                id="sms-temp"
                role="tabpanel"
                aria-labelledby="sms-temp-tab"
              >
                <div className="row">
                  <div className="col-md-6 mt-10">
                    <Search
                      search={search}
                      setSearch={setSearch}
                      onSearch={onSearch}
                      placeholder="sms name"
                    />
                  </div>
                  <div className="col-md-6 mt-10">
                    <button className="btn btn-theme-green float-right mt-1">
                      + SMS template
                    </button>
                  </div>
                  <div className="col-md-12 mt-10">
                    {/* <TimeZone
                      headRow={["Time Zone", "Country Code", "Action"]}
                      list={[]}
                    /> */}
                  </div>
                </div>
              </div>
              {/* notification */}
              <div
                className="tab-pane fade"
                id="notification"
                role="tabpanel"
                aria-labelledby="notification-tab"
              >
                <div className="row">
                  <div className="col-md-6 mt-10">
                    <Search
                      search={search}
                      setSearch={setSearch}
                      onSearch={onSearch}
                      placeholder="notification name"
                    />
                  </div>
                  <div className="col-md-6 mt-10">
                    <button className="btn btn-theme-green float-right mt-1">
                      + Notification
                    </button>
                  </div>
                  <div className="col-md-12 mt-10">
                    {/* <TimeZone
                      headRow={["Time Zone", "Country Code", "Action"]}
                      list={[]}
                    /> */}
                  </div>
                </div>
              </div>
              {/* online-form */}
              <div
                className="tab-pane fade"
                id="online-form"
                role="tabpanel"
                aria-labelledby="online-form-tab"
              >
                <div className="row">
                  <div className="col-md-6 mt-10">
                    <Search
                      search={search}
                      setSearch={setSearch}
                      onSearch={onSearch}
                      placeholder="form name"
                    />
                  </div>
                  <div className="col-md-6 mt-10">
                    <button className="btn btn-theme-green float-right mt-1">
                      + Form
                    </button>
                  </div>
                  <div className="col-md-12 mt-10">
                    {/* <TimeZone
                      headRow={["Time Zone", "Country Code", "Action"]}
                      list={[]}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
