import React from "react";

function RadioInput(props) {
  return (
    <>
      <div class="custom-control1 custom-radio custom-control-inline">
        <input
          {...props?.require}
          onChange={(e) => props.handleChange(e)}
          type="radio"
          id={props?.id}
          value={props?.val}
          checked={props?.val == props?.value ? "checked" : ""}
          name={props?.name}
          className={`custom-control-input ${props.err && "is-invalid"}`}
        />
        <label class="custom-control-label" for={props?.id}>
          {props?.label}
        </label>
      </div>
    </>
  );
}

export default RadioInput;
