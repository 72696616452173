import React, { useState } from "react";
import { IoIosTrash, IoMdCreate } from "react-icons/io";
import { useHistory } from "react-router-dom";
import DeleteConfirmationPopup from "../Popup/DeleteConfirmationPopup";

function IcdCodeTable(props) {
  const { confirmDeletion, setConfirmDeletion } = props;
  const history = useHistory();
  return (
    <div className="table-responsive">
      <table class="table">
        <thead>
          <tr>
            {props.headRow.map((data) => {
              return <th scope="col">{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {props?.list &&
            props?.list.map((data) => {
              return (
                <tr>
                  <td>{data?.therapyDto?.name}</td>
                  <td>{data?.diagnosisCode}</td>
                  <td>{data?.diagnosisDescription}</td>
                  <td>
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() =>
                        history.push(`edit-icd-10-code/${data.id}`)
                      }
                    >
                      <IoMdCreate />
                    </button>
                    <button
                      className="btn btn-theme-red ml-10 mb-10"
                      onClick={() => setConfirmDeletion(data)}
                    >
                      <IoIosTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
        {confirmDeletion && (
          <DeleteConfirmationPopup
            confirmDeletion={confirmDeletion}
            setConfirmDeletion={setConfirmDeletion}
            onDelete={props?.onDelete}
            name={confirmDeletion?.diagnosisCode}
          />
        )}
      </table>
    </div>
  );
}

export default IcdCodeTable;
