import React from "react";
import TextInput from "../../Inputs/TextInput";
import { nameRegex, urlRegex } from "../../../Utils/Regex";
import { useForm } from "react-hook-form";
import InputFileBtn from "../../Inputs/InputFileBtn";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadFile from "../../../Utils/UploadFile";

function IndividualTenantForm(props) {
  let params = useParams();
  const { onSubmit, handleChange, inputs, handleImageChange, isOnboard } =
    props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <>
      <form
        className="addtheripist add-session-form p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row mt-10">
          <div className="col-md-6">
            <TextInput
              label="Tenant Id"
              name="tenantId"
              handleChange={handleChange}
              require={{
                ...register("tenantId", {
                  required: true,
                  maxLength: 50,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.tenantId}
              err={errors?.tenantId}
              isRequire={true}
              placeholder="tenant id"
            />
          </div>
          <div className="col-md-6">
            <TextInput
              label="Tenant URL"
              name="tenantUrl"
              handleChange={handleChange}
              require={{
                ...register("tenantUrl", {
                  required: true,
                  pattern: urlRegex,
                }),
              }}
              value={inputs?.tenantUrl}
              err={errors?.tenantUrl}
              isRequire={true}
              placeholder="tenant url"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-0 mt-10">
              <label>Tenant Logo</label>{" "}
              {!isOnboard && params?.id ? (
                <span>
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={
                          inputs?.logoUrl
                            ? inputs.logoUrl
                            : "/resources/assets/defaultprofile.png"
                        }
                        alt=""
                        className="settingsimg mb-0"
                      />
                    </div>
                    <InputFileBtn
                      id="fileuplaod"
                      name="logoUrl"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                  <br />
                </span>
              ) : (
                <label for="fileuplaod" className="fileuplaod">
                  <div className="content-box">
                    <img
                      src={
                        inputs?.logoUrl
                          ? inputs.logoUrl
                          : "../resources/assets/file-upload-icon.svg"
                      }
                      alt="Family"
                    />
                    <div className="file-title">
                      Drop your image here, or <strong>browse</strong>{" "}
                    </div>
                    <div className="file-name">Support- JPG,JPEG,PNG,SVG</div>
                  </div>
                  <input
                    type="file"
                    id="fileuplaod"
                    name="logoUrl"
                    onChange={(e) => handleImageChange(e)}
                    //ref={register({ required: inputs.logoUrl ? false : true })}
                  />
                </label>
              )}
              {errors.logoUrl && errors.logoUrl.type === "required" && (
                <div className="invalid-feedback-custom">
                  Please upload profile picture
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5>Billing Company</h5>
          </div>
          <div className="col-md-6 mt-10">
            <TextInput
              label="Full Name"
              name="companyName"
              handleChange={handleChange}
              require={{
                ...register("companyName", {
                  required: true,
                  maxLength: 50,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.companyName}
              err={errors?.companyName}
              isRequire={true}
              placeholder="company name"
            />
          </div>
          <div className="col-md-12">
            <TextInput
              label="Address 1"
              name="address1"
              handleChange={handleChange}
              require={{
                ...register("address1", {
                  required: true,
                  maxLength: 100,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.address1}
              err={errors?.address1}
              isRequire={true}
              placeholder="an address 1"
            />
          </div>
          <div className="col-md-12">
            <TextInput
              label="Address 2"
              name="address2"
              handleChange={handleChange}
              require={{
                ...register("address2", {
                  required: true,
                  maxLength: 100,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.address2}
              err={errors?.address2}
              isRequire={true}
              placeholder="an address 2"
            />
          </div>

          <div className="col-md-3">
            <TextInput
              label="City"
              name="city"
              handleChange={handleChange}
              require={{
                ...register("city", {
                  required: true,
                  maxLength: 50,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.city}
              err={errors?.city}
              isRequire={true}
              placeholder="the city"
            />
          </div>
          <div className="col-md-3">
            <TextInput
              label="State"
              name="state"
              handleChange={handleChange}
              require={{
                ...register("state", {
                  required: true,
                  maxLength: 50,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.state}
              err={errors?.state}
              isRequire={true}
              placeholder="the state"
            />
          </div>
          <div className="col-md-3">
            <TextInput
              label="Country"
              name="country"
              handleChange={handleChange}
              require={{
                ...register("country", {
                  required: true,
                  maxLength: 50,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.country}
              err={errors?.country}
              isRequire={true}
              placeholder="the country"
            />
          </div>
          <div className="col-md-3">
            <TextInput
              type="number"
              label="Zip Code"
              name="zipCode"
              handleChange={handleChange}
              require={{
                ...register("zipCode", {
                  required: true,
                  maxLength: 6,
                  pattern: nameRegex,
                }),
              }}
              value={inputs?.zipCode}
              err={errors?.zipCode}
              isRequire={true}
              placeholder="the zip code"
            />
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-theme-green nxt-btn">
              {isOnboard ? "Next" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default IndividualTenantForm;
