import React from "react";
import AppRoutes from "./Router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { UserProvider } from "./components/Context/UserContext";

function App() {
  return (
    <UserProvider>
      <Router>
        <Switch>
          <Route component={AppRoutes} />
        </Switch>
      </Router>
    </UserProvider>
  );
}

export default App;
