import React from "react";

function AdminRESTable(props) {
  const { list } = props;
  return (
    <>
      {list &&
        list.map((data) => {
          return (
            <div className="border-bottom mt-10">
              <div className="row m-0">
                <div className="col-md-1">
                  <img
                    src={"/resources/assets/defaultprofile.png"}
                    alt="User profile img"
                    className="tenant-detail-logo"
                    width="100%"
                  />
                </div>
                <div className="col-md-11">
                  <div className="row m-0">
                    <div className="col-md-10">
                      <h4>Demo Name</h4>
                    </div>
                    <div className="col-md-2">
                      <img
                        src={"/resources/assets/action-icon-edit.svg"}
                        alt="User profile img"
                        className="float-right"
                      />
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col-md-3">
                      <p>Email</p>
                      <p>demo@gmail.com</p>
                    </div>
                    <div className="col-md-9">
                      <p>Phone</p>
                      <p>8295835893</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

export default AdminRESTable;
