import React from "react";
import { Link, useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();
  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <Link onClick={() => history.push(`/dashboard`)}>
                <img
                  className="tenant-logo-img-header"
                  src="../resources/assets/MIS-1.png"
                  alt=""
                  width="35%"
                />
              </Link>
            </div>
            <div className="col-auto">
              <div className="user-info">
                <span className="admin-name">
                  <img
                    src={"/resources/assets/defaultprofile.png"}
                    className="image"
                    alt="User profile img"
                  />
                  <div className="content ml-2">
                    <sapn className="text-capitalize">Super Admin</sapn>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
