import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nameRegex, urlRegex } from "../../Utils/Regex";
import TextInput from "../Inputs/TextInput";
import Service from "../../../Service";
import { toast } from "react-toastify";

function AddEditTherapyType() {
  let history = useHistory();
  const params = useParams();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [inputs, setInputs] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  useEffect(() => {
    params?.id && getTherapyDetail(params?.id);
  }, []);

  const getTherapyDetail = (id) => {
    Service.getMethod(`therapies/${id}`)
      .then((res) => {
        if (res.status == "Success") {
          setInputs(res?.data);
          setValue("name", res?.data.name);
          setValue("code", res?.data.code);
          setValue("description", res?.data.description);
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  const onSubmit = () => {
    const fn = params?.id
      ? Service.putMethod(`therapies/${params?.id}`, JSON.stringify(inputs))
      : Service.postMethod(`therapies`, JSON.stringify(inputs));

    fn.then((res) => {
      if (res.status == "Success") {
        res?.data && setInputs(res?.data);
        toast.success(res?.message);
        history.push("/therapy-types");
      } else {
        toast.error(res?.message || "Somthing went wrong");
      }
    }).catch((err) => toast.error(err));
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="card border-none p-10">
          <div className="row">
            <div className="col-md-12">
              <h4>{params?.id ? "Edit" : "Add"} Therapy Type</h4>
            </div>
          </div>
          <hr />
          <form className="p-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <TextInput
                  label="Therapy name"
                  name="name"
                  handleChange={handleChange}
                  require={{
                    ...register("name", {
                      required: true,
                    }),
                  }}
                  value={inputs?.name}
                  err={errors?.name}
                  isRequire={true}
                  placeholder="Therapy Name"
                />
              </div>
              <div className="col-md-6">
                <TextInput
                  label="Therapy Code"
                  name="code"
                  handleChange={handleChange}
                  require={{
                    ...register("code", {
                      required: true,
                      maxLength: 50,
                      pattern: nameRegex,
                    }),
                  }}
                  value={inputs?.code}
                  err={errors?.code}
                  isRequire={true}
                  placeholder="Therapy Code"
                />
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-md-12 form-group mb-0">
                <label>
                  Description <span class="required-span">*</span>
                </label>
                <textarea
                  {...register("description", {
                    required: true,
                  })}
                  name="description"
                  className={`form-control h-100 ${
                    errors?.description && "is-invalid"
                  }`}
                  onChange={(e) => handleChange(e)}
                  value={inputs?.description}
                  placeholder="Enter description"
                />
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12">
                <button type="submit" className="btn btn-theme-green">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditTherapyType;
