import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import IcdCodeTable from "../CustomTable/IcdCodeTable";
import Search from "../Search";
import Service from "../../../Service";
import { toast } from "react-toastify";
import Pagination from "react-responsive-pagination";
import CptCodeTable from "../CustomTable/CptCodeTable";

function CptCode() {
  let history = useHistory();
  const [search, setSearch] = useState("");
  const [cptList, setCptList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const onSearch = () => {
    getCptList();
  };

  useEffect(() => {
    getCptList();
  }, [search, page]);

  const getCptList = (id) => {
    Service.getMethod(`cpts?search=${search}&page=${page}&size=10&sort=id,desc`)
      .then((res) => {
        if (res.status == "Success") {
          setCptList(res?.data.content);
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  const deleteData = (id) => {
    Service.deleteMethod(`cpts/${id}`)
      .then((res) => {
        if (res.status == "Success") {
          setConfirmDeletion(false);
          toast.success(res?.message);
          getCptList();
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  return (
    <div className="col-md-10 no-padding">
      <div className="card border-none p-10">
        <div className="row">
          <div className="col-md-6">
            <h4>CPT Codes</h4>
          </div>
          <div className="col-md-6">
            <button
              onClick={() => history.push("/add-cpt-code")}
              className="btn btn-theme-green float-right"
            >
              + CPT Code
            </button>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 mt-10">
            <Search
              search={search}
              setSearch={setSearch}
              onSearch={onSearch}
              placeholder="CPT Code"
            />
          </div>
          <div className="col-md-12 mt-10">
            <CptCodeTable
              headRow={["Therapy Name", "CPT code", "Description", "Action"]}
              list={cptList || []}
              onDelete={deleteData}
              confirmDeletion={confirmDeletion}
              setConfirmDeletion={setConfirmDeletion}
            />
          </div>
          <div className="col-md-12 mt-10">
            <Pagination
              total={totalPages}
              current={page + 1}
              onPageChange={(page) => setPage(page - 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CptCode;
