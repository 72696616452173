import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TenantTable from "../CustomTable";
import Search from "../Search";
import Service from "../../../Service";
import { toast } from "react-toastify";
import Pagination from "react-responsive-pagination";

function Tenant() {
  let history = useHistory();
  const [search, setSearch] = useState("");
  const [tenantList, setTenantList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const onSearch = () => {
    getTenantList();
  };

  useEffect(() => {
    getTenantList();
  }, [search, page]);

  const getTenantList = (id) => {
    Service.getMethod(
      `tenants?search=${search}&page=${page}&size=10&sort=id,desc`
    )
      .then((res) => {
        if (res.status == "Success") {
          setTenantList(res?.data);
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  const deleteData = (id) => {
    Service.deleteMethod(`tenants/${id}`)
      .then((res) => {
        if (res.status == "Success") {
          setConfirmDeletion(false);
          toast.success(res?.message);
          getTenantList();
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };
  return (
    <div className="col-md-10 no-padding">
      <div className="card border-none p-10">
        <div className="row">
          <div className="col-md-6">
            <h4>Tenants</h4>
          </div>
          <div className="col-md-6">
            <button
              onClick={() => history.push("/add-tenant")}
              className="btn btn-theme-green float-right"
            >
              + Tenant
            </button>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 mt-10">
            <Search search={search} setSearch={setSearch} onSearch={onSearch} />
          </div>
          <div className="col-md-12 mt-10">
            <TenantTable
              // headRow={["Name", "URL", "No. of Admins", "No. RES", "Action"]}
              headRow={["Tenant Id", "Logo", "URL", "Name", "Action"]}
              list={tenantList || []}
              onDelete={deleteData}
              confirmDeletion={confirmDeletion}
              setConfirmDeletion={setConfirmDeletion}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tenant;
