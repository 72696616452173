import React from "react";

function TimeZone(props) {
  return (
    <div className="table-responsive">
      <table class="table">
        <thead>
          <tr>
            {props.headRow.map((data) => {
              return <th scope="col">{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {props.list.map((data) => {
            return (
              <tr>
                <td>{data?.timezone}</td>
                <td>{data?.countryCode}</td>
                <td>Action</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TimeZone;
