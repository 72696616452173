import React, { useState } from "react";
import TextInput from "../Inputs/TextInput";
import { nameRegex, emailRegex } from "../../Utils/Regex";
import { useForm } from "react-hook-form";
import InputFileBtn from "../Inputs/InputFileBtn";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadFile from "../../Utils/UploadFile";
import RadioInput from "../Inputs/RadioInput";

function AddEditOtherUser() {
  let params = useParams();
  let history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    profileImage: "",
    about: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onSubmit = () => {
    console.log("good");
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    UploadFile(e, setInputs);
  };
  console.log("params", params);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="card border-none">
          <div className="row p-10">
            <div className="col-md-6">
              <h4>{params?.id ? "Edit" : "Add"} Tenant Admin</h4>
            </div>
            <div className="col-md-6">
              <button
                onClick={() => history.push("/tenant-detail/1")}
                className="btn btn-theme-green float-right"
              >
                Back
              </button>
            </div>
          </div>
          <hr className="mt-0" />
          <div className="col-md-12">
            <form
              className="addtheripist add-session-form p-0"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row mt-10">
                <div className="col-12">
                  <div className="form-group">
                    <label>Profile Picture</label>{" "}
                    {params.id ? (
                      <span>
                        <div className="row">
                          <div className="col-md-4">
                            <img
                              src={
                                inputs?.profileImage
                                  ? inputs.profileImage
                                  : "/resources/assets/defaultprofile.png"
                              }
                              alt=""
                              className="settingsimg"
                            />
                          </div>
                          <InputFileBtn
                            id="fileuplaod"
                            name="profileImage"
                            onChange={(e) => handleImageChange(e)}
                          />
                        </div>
                        <br />
                      </span>
                    ) : (
                      <label for="fileuplaod" className="fileuplaod">
                        <div className="content-box">
                          <img
                            src={
                              inputs?.profileImage
                                ? inputs.profileImage
                                : "../resources/assets/file-upload-icon.svg"
                            }
                            alt="Family"
                          />
                          <div className="file-title">
                            Drop your image here, or <strong>browse</strong>{" "}
                          </div>
                          <div className="file-name">
                            Support- JPG,JPEG,PNG,SVG
                          </div>
                        </div>
                        <input
                          type="file"
                          id="fileuplaod"
                          name="profileImage"
                          onChange={(e) => handleImageChange(e)}
                          //ref={register({ required: inputs.profileImage ? false : true })}
                        />
                      </label>
                    )}
                    {errors.profileImage &&
                      errors.profileImage.type === "required" && (
                        <div className="invalid-feedback-custom">
                          Please upload profile picture
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="row mt-10">
                <div className="col-md-6">
                  <TextInput
                    label="First Name"
                    name="firstName"
                    handleChange={handleChange}
                    require={{
                      ...register("firstName", {
                        required: true,
                        maxLength: 50,
                        pattern: nameRegex,
                      }),
                    }}
                    value={inputs?.firstName}
                    err={errors?.firstName}
                    isRequire={true}
                    placeholder="first name"
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    handleChange={handleChange}
                    require={{
                      ...register("lastName", {
                        required: true,
                        pattern: nameRegex,
                      }),
                    }}
                    value={inputs?.lastName}
                    err={errors?.lastName}
                    isRequire={true}
                    placeholder="last name"
                  />
                </div>
              </div>

              <div className="row mt-10">
                <div className="col-md-6">
                  <TextInput
                    label="Email"
                    name="email"
                    handleChange={handleChange}
                    require={{
                      ...register("email", {
                        required: true,
                        maxLength: 50,
                        pattern: emailRegex,
                      }),
                    }}
                    value={inputs?.email}
                    err={errors?.email}
                    isRequire={true}
                    placeholder="an email"
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    type="number"
                    label="Phone Number"
                    name="phone"
                    handleChange={handleChange}
                    require={{
                      ...register("phone", {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                      }),
                    }}
                    value={inputs?.phone}
                    err={errors?.phone}
                    isRequire={true}
                    placeholder="phone number"
                  />
                </div>
              </div>
              {!params?.id && (
                <div className="row mt-10">
                  <div className="col-md-6">
                    <TextInput
                      type="password"
                      label="Password"
                      name="password"
                      handleChange={handleChange}
                      require={{
                        ...register("password", {
                          required: true,
                          maxLength: 50,
                          pattern: nameRegex,
                        }),
                      }}
                      value={inputs?.password}
                      err={errors?.password}
                      isRequire={true}
                      placeholder="password"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      type="password"
                      label="Confirm password"
                      name="cpassword"
                      handleChange={handleChange}
                      require={{
                        ...register("cpassword", {
                          required: true,
                          pattern: nameRegex,
                        }),
                      }}
                      value={inputs?.cpassword}
                      err={errors?.cpassword}
                      isRequire={true}
                      placeholder="confirm password"
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-12 form-group mb-15">
                  <label>Gender</label> (
                  <span className="required-span">*</span>)
                  <br />
                  <RadioInput
                    label="Male"
                    name="gender"
                    handleChange={handleChange}
                    require={{
                      ...register("gender", {
                        required: true,
                      }),
                    }}
                    val="MALE"
                    value={inputs?.gender}
                    err={errors?.gender}
                    id="customRadi1"
                  />
                  <RadioInput
                    label="Female"
                    name="gender"
                    handleChange={handleChange}
                    require={{
                      ...register("gender", {
                        required: true,
                      }),
                    }}
                    val="FEMALE"
                    value={inputs?.gender}
                    err={errors?.gender}
                    id="customRadio2"
                  />
                  <RadioInput
                    label="Other"
                    name="gender"
                    handleChange={handleChange}
                    require={{
                      ...register("gender", {
                        required: true,
                      }),
                    }}
                    val="NOT_DISCLOSED"
                    value={inputs?.gender}
                    err={errors?.gender}
                    id="customRadio3"
                  />
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-md-12 form-group mb-0">
                  <label>
                    About <span class="required-span">*</span>
                  </label>
                  <textarea
                    name="about"
                    handleChange={handleChange}
                    {...register("about", {
                      required: true,
                      pattern: nameRegex,
                    })}
                    value={inputs?.about}
                    isRequire={true}
                    placeholder="Enter about tenant admin"
                    className={`form-control h-100 ${
                      errors?.about && "is-invalid"
                    }`}
                  />
                </div>

                <div className="col-md-12 mt-10">
                  <button type="submit" className="btn btn-theme-green nxt-btn">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditOtherUser;
