import React from "react";
import { Link, useLocation, useHistory, generatePath } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";
import Service from "../../../Service";

const Sidebar = () => {
  let location = useLocation();
  let history = useHistory();

  const Logout = () => {
    // localStorage.removeItem("superadmin");
    Service.patchMethod("auth/logout")
      .then((res) => {
        console.log("res");
        if (res.status == "Success") {
          localStorage.removeItem("superadmin");
          window.location.href = "/login";
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err));
  };
  const adminMenus = [
    {
      link: "/dashboard",
      imgSrc: "/resources/assets/dashboard.svg",
      name: "Dashboard",
      isMenu: true,
    },
    {
      link: "#",
      imgSrc: "/resources/assets/Icon feather-monitor.svg",
      name: "Onboarding",
      pathIncludes: [
        "/tenant",
        "/add-tenant",
        "/edit-tenant",
        "/edit-tenant-admin",
        "/edit-tenant-res",
        "tenant-detail",
        "other-users",
        "add-other-user",
        "edit-other-user",
      ],
      isMenu: true,
    },
    {
      link: "/tenant",
      imgSrc: "/resources/assets/Icon feather-monitor.svg",
      name: "Tenant",
      pathIncludes: [
        "/tenant",
        "/add-tenant",
        "/edit-tenant",
        "/edit-tenant-admin",
        "/edit-tenant-res",
        "/tenant-detail",
      ],
      class: "dropdown-active",
      isMenu: false,
    },
    {
      link: "/other-users",
      imgSrc: "/resources/assets/blank-user.svg",
      name: "Other User",
      pathIncludes: [
        "other-users",
        "add-other-user",
        "edit-other-user",
        "other-user-detail",
      ],
      class: "dropdown-active",
      isMenu: false,
    },
    // {
    //   link: "/time-zone",
    //   imgSrc: "/resources/assets/b-clock.svg",
    //   name: "Timezone",
    // },
    // {
    //   link: "/forms",
    //   imgSrc: "/resources/assets/file-earmark-text.svg",
    //   name: "Forms",
    // },
    {
      link: "#",
      imgSrc: "/resources/assets/file-earmark-text.svg",
      name: "Therapy License",
      pathIncludes: [
        "icd-10-codes",
        "add-icd-10-code",
        "edit-icd-10-code",
        "cpt-codes",
        "add-cpt-code",
        "edit-cpt-code",
        "therapy-types",
        "add-therapy-type",
        "edit-therapy-type",
      ],
      isMenu: true,
    },
    {
      link: "/icd-10-codes",
      imgSrc: "/resources/assets/Icon feather-users.png",
      name: "ICD 10 Codes",
      class: "dropdown-active",
      pathIncludes: ["icd-10-code", "add-icd-10-code", "edit-icd-10-code"],
      isMenu: false,
    },
    {
      link: "/cpt-codes",
      imgSrc: "/resources/assets/Icon feather-users.png",
      name: "CPT Codes",
      class: "dropdown-active",
      pathIncludes: ["cpt-codes", "add-cpt-code", "edit-cpt-code"],
      isMenu: false,
    },
    {
      link: "/therapy-types",
      imgSrc: "/resources/assets/Icon feather-users.png",
      name: "Therapy Types",
      class: "dropdown-active",
      pathIncludes: ["therapy-type", "add-therapy-type", "edit-therapy-type"],
      isMenu: false,
    },
  ];

  let bottomCommonMenus = [
    {
      link: "/settings",
      imgSrc: "/resources/assets/Icon feather-settings.svg",
      name: "Settings",
      isMenu: true,
    },
    {
      link: "#",
      onClick: Logout,
      imgSrc: "/resources/assets/Icon feather-log-out.svg",
      name: "Logout",
      isMenu: true,
    },
  ];
  const SidebarLink = ({
    link,
    isActive,
    imgSrc,
    name,
    onClick,
    isActiveMenu,
    isMenu,
  }) => {
    return (
      <li
        className={classNames({
          "sidebar-active": isActive,
          "dropdown-active": isActiveMenu,
        })}
      >
        <Link to={link} onClick={onClick}>
          {isMenu && (
            <img
              src={`${window.location.origin}${imgSrc}`}
              alt=""
              className="img-fluid"
            />
          )}{" "}
          <span>{name}</span>
        </Link>
      </li>
    );
  };

  const Menus = ({ menus, pathname }) => (
    <ul className="sidebar-item">
      {menus.map((item, i) => {
        const isActive = [item.link, item.pathIncludes]
          .flat()
          .some((item) => pathname.includes(item));
        const isActiveMenu = item?.class || false;
        return (
          <SidebarLink
            key={item.link + i}
            {...item}
            isActive={isActive}
            isActiveMenu={isActiveMenu}
            isMenu={item.isMenu}
          />
        );
      })}
    </ul>
  );

  return (
    <>
      <div className="c-sidebar col-md-2 no-padding">
        {/* {user && !user.userDetails.newUser && (
          <>
            <Menus menus={topMenus} pathname={location.pathname} />
            <hr />
            <Menus menus={bottomCommonMenus} pathname={location.pathname} />
          </>
        )} */}

        <Menus menus={adminMenus} pathname={location.pathname} />
        <hr />
        <Menus menus={bottomCommonMenus} pathname={location.pathname} />

        <div className="copyright">
          <img
            src="../resources/assets/MIS-1.png"
            className="copyright-image-sidebar "
            alt="mis"
          />
          <span className="copyright-text-sidebar">Copyright @RCE 2021</span>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
