import React from "react";

function TenantTable(props) {
  return (
    <div className="table-responsive">
      <table class="table">
        <thead>
          <tr>
            {props.headRow.map((data) => {
              return <th scope="col">{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {props.list.map((data) => {
            return (
              <tr>
                <td>{data?.tenantConfigDto?.tenantId}</td>
                <td>
                  <img
                    className="small-tenant-img"
                    src={
                      data?.smallLogoUrl
                        ? data?.smallLogoUrl
                        : "../../../resources/assets/defaultprofile.png"
                    }
                  />
                </td>
                <td>{data?.tenantConfigDto?.tenantUrl}</td>
                <td>{data?.tenantConfigDto?.name}</td>
                {/* <td>{data?.tenantConfigDto?.comapnyName}</td> */}
                <td>Action</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TenantTable;
