import React, { useState } from "react";
import TextInput from "../../Inputs/TextInput";
import { nameRegex, urlRegex } from "../../../Utils/Regex";
import { useForm } from "react-hook-form";
import InputFileBtn from "../../Inputs/InputFileBtn";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadFile from "../../../Utils/UploadFile";
import IndividualTenantForm from "../OnboardSteps/IndividualTenantForm";

function EditIndividual() {
  let history = useHistory();
  const [inputs, setInputs] = useState({
    tenantId: "",
    tenantUrl: "",
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    logoUrl: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onSubmit = () => {
    console.log("good");
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    UploadFile(e, setInputs);
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="card border-none">
          <div className="row p-10">
            <div className="col-md-6">
              <h4>Edit Individual Tenant</h4>
            </div>
            <div className="col-md-6">
              <button
                onClick={() => history.push("/tenant-detail/1")}
                className="btn btn-theme-green float-right"
              >
                Back
              </button>
            </div>
          </div>
          <hr className="mt-0" />
          <div className="col-md-12">
            <IndividualTenantForm
              onSubmit={onSubmit}
              handleChange={handleChange}
              inputs={inputs}
              handleImageChange={handleImageChange}
              isOnboard={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditIndividual;
