import React from "react";

function TabLayout(props) {
  const { currentTab, setCurrentTab, list } = props;
  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {list.map((data) => {
          return (
            <li
              className="nav-item"
              onClick={() => setCurrentTab(data.manageId)}
            >
              <a
                className={`nav-link ${
                  currentTab == data.manageId && "text-left active"
                }`}
                id={`${data.manageId}-tab`}
                data-toggle="tab"
                href={`#${data.manageId}`}
                role="tab"
                aria-controls={data.manageId}
                aria-selected="true"
              >
                <span>
                  {data?.title} {data?.noOfUser && `(${data?.noOfUser})`}
                </span>
                {currentTab == data.manageId &&
                  data?.buttonComp &&
                  data.buttonComp}
              </a>
            </li>
          );
        })}
      </ul>

      <div
        className="d-block tab-content"
        id="myTabContent"
        style={{ paddingBottom: 20 }}
      >
        {list.map((data) => {
          return (
            <>
              <div
                className={`tab-pane fade ${
                  currentTab == data.manageId && "active show"
                }`}
                id={data.manageId}
                role="tabpanel"
                aria-labelledby={`${data.manageId}-tab`}
              >
                <div className="mt-10">{data.component}</div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default TabLayout;
