import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import OtherTable from "../CustomTable/OtherTable";
import Search from "../Search";

function OtherUser() {
  let history = useHistory();
  const [search, setSearch] = useState("");
  const onSearch = () => {
    console.log("search", search);
  };
  return (
    <div className="col-md-10 no-padding">
      <div className="card border-none p-10">
        <div className="row">
          <div className="col-md-6">
            <h4>Other Users</h4>
          </div>
          <div className="col-md-6">
            <button
              onClick={() => history.push("/add-other-user")}
              className="btn btn-theme-green float-right"
            >
              + Other User
            </button>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 mt-10">
            <Search search={search} setSearch={setSearch} onSearch={onSearch} />
          </div>
          <div className="col-md-12 mt-10">
            <OtherTable
              headRow={["", "Name", "Email", "Phone", "Gender", "Action"]}
              list={[]}
              tableName="other"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherUser;
