import React, { createContext, useContext, useReducer, useState } from "react";
import Service from "../../Service";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const user = localStorage.getItem("superadmin")
    ? JSON.parse(localStorage.getItem("superadmin"))
    : {};
  const value = {
    user,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useUserContext() {
  return useContext(UserContext);
}
