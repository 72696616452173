import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nameRegex, urlRegex } from "../../Utils/Regex";
import TextInput from "../Inputs/TextInput";
import Service from "../../../Service";
import { toast } from "react-toastify";

function AddEditIcdTenCode() {
  let history = useHistory();
  const params = useParams();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [inputs, setInputs] = useState({});
  const [therapyList, setTherapyList] = useState([]);

  useEffect(() => {
    getTherapyTypes();
  }, []);

  useEffect(() => {
    params?.id && getIcdCodeDetail(params?.id);
  }, [params?.id]);

  const getIcdCodeDetail = (id) => {
    Service.getMethod(`diagnosis/${id}`)
      .then((res) => {
        if (res.status == "Success") {
          const data = res?.data;
          setInputs({ ...inputs, ...data, therapyId: res?.data.therapy.id });
          // setInputs({ ...inputs, therapy: res?.data.therapyDto.id });
          setValue("therapyId", res?.data.therapy.id);
          setValue("diagnosisCode", res?.data.diagnosisCode);
          setValue("diagnosisDescription", res?.data.diagnosisDescription);
        } else {
          toast.error(res?.message || "Somthing went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  const getTherapyTypes = () => {
    Service.getMethod(`therapies`)
      .then((res) => {
        if (res.status == "Success") {
          setTherapyList(res?.data.content);
        }
      })
      .catch((err) => toast.error(err));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: name == "therapyId" ? parseInt(value) : value,
    });
  };

  const onSubmit = () => {
    const fn = params?.id
      ? Service.putMethod(`diagnosis/${params?.id}`, JSON.stringify(inputs))
      : Service.postMethod(`diagnosis`, JSON.stringify(inputs));

    fn.then((res) => {
      if (res.status == "Success") {
        res?.data && setInputs(res?.data);
        history.push("/icd-10-codes");
        toast.success(res?.message);
      } else {
        toast.error(res?.message || "Somthing went wrong");
      }
    }).catch((err) => toast.error(err));
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="card border-none p-10">
          <div className="row">
            <div className="col-md-6">
              <h4>{params?.id ? "Edit" : "Add"} ICD 10 Code</h4>
            </div>
            <div className="col-md-6">
              {/* <button
                onClick={() => history.push("/icd-10-codes")}
                className="btn btn-theme-green float-right"
              >
                Back
              </button> */}
            </div>
          </div>
          <hr />
          <form className="p-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 form-group mb-0 add-session-form">
                <label>
                  Therapy Name <span class="required-span">*</span>
                </label>
                <div className="input-group">
                  <select
                    {...register("therapyId", {
                      required: true,
                    })}
                    className={`custom-select ${
                      errors.therapyId && "is-invalid"
                    }`}
                    onChange={handleChange}
                    defaultValue={inputs?.therapyId}
                    value={inputs?.therapyId}
                    name="therapyId"
                    disabled={params?.id ? "disabled" : ""}
                  >
                    <option value="">Select Therapy</option>
                    {therapyList.length > 0 &&
                      therapyList.map((th) => {
                        return <option value={th.id}>{th.name}</option>;
                      })}
                  </select>
                  <div className="input-group-prepend">
                    <img
                      className="m-auto"
                      src="../resources/assets/Polygon 1.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <TextInput
                  label="ICD 10 Code"
                  name="diagnosisCode"
                  handleChange={handleChange}
                  require={{
                    ...register("diagnosisCode", {
                      required: true,
                      maxLength: 50,
                    }),
                  }}
                  value={inputs?.diagnosisCode}
                  err={errors?.diagnosisCode}
                  isRequire={true}
                />
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-md-12 form-group mb-0">
                <label>
                  Description <span class="required-span">*</span>
                </label>
                <textarea
                  {...register("diagnosisDescription", {
                    required: true,
                  })}
                  name="diagnosisDescription"
                  className={`form-control h-100 ${
                    errors?.diagnosisDescription && "is-invalid"
                  }`}
                  onChange={(e) => handleChange(e)}
                  value={inputs?.diagnosisDescription}
                  placeholder="Enter description"
                />
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12">
                <button type="submit" className="btn btn-theme-green">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditIcdTenCode;
