import React from "react";

function StepperHeader(props) {
  const { steps, activeStep } = props;
  return (
    <>
      <div className="stepper mt-0">
        {steps.map((label, index) => (
          <div
            className={`col-md-${steps.length == 3 ? 4 : 3} stepper-steps ${
              activeStep == index && activeStep != steps.length - 1
                ? "active"
                : activeStep > index
                ? "complete"
                : activeStep === steps.length - 1
                ? "complete done"
                : ""
            }`}
          >
            <div className="step-count">
              <label>{index + 1}</label>
            </div>{" "}
            <label className="label-text-family">{label}</label>
          </div>
        ))}
      </div>
    </>
  );
}

export default StepperHeader;
