import React from "react";
import { IoIosTrash, IoMdCreate } from "react-icons/io";
import { useHistory } from "react-router-dom";

function TherapyTypeTable(props) {
  const history = useHistory();
  return (
    <div className="table-responsive">
      <table class="table">
        <thead>
          <tr>
            {props.headRow.map((data) => {
              return <th scope="col">{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {props?.list &&
            props.list.map((data) => {
              return (
                <tr>
                  <td>{data?.name}</td>
                  <td>{data?.code}</td>
                  <td>{data?.description}</td>
                  <td>
                    <button
                      className="btn btn-theme-green"
                      onClick={() =>
                        history.push(`edit-therapy-type/${data.id}`)
                      }
                    >
                      <IoMdCreate />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default TherapyTypeTable;
